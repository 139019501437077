import { graphql, Link } from "gatsby";
import React from "react";
import { CircleArrow as ScrollUpButton } from "react-scroll-up-button";
import Article from "../components/article";
import LineBreak from "../components/lineBreak";
import SEO from "../components/seo";
import Sidebar from "../components/sidebar";
import events from "../data/events";
import { parseDateString } from "../tools/utils";

const DanceEvent = ({ name, startDate, endDate, place, dress, notes }) => {
  const timeOptions = {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  };
  const parsedStartDate = parseDateString(startDate);
  const parsedStartTime = new Intl.DateTimeFormat("en-GB", timeOptions).format(
    parsedStartDate.dateTime
  );
  const parsedEndDate = parseDateString(endDate);
  const parsedEndTime = new Intl.DateTimeFormat("en-GB", timeOptions).format(
    parsedEndDate.dateTime
  );
  const day = parsedStartDate.getFormattedDay();
  const dayDateTime = parsedStartDate.dateTime
    .toISOString()
    .substring(0, 10)
    .replace(/\//g, "-");

  return (
    <div className="ml-2">
      <div className="font-semibold text-highlight">
        <time dateTime={dayDateTime}>{day}</time>
      </div>
      <div>
        <dl className="ml-2">
          {name && <dt className="font-semibold text-highlight">{name}</dt>}
          <dt className="font-semibold">{place}</dt>
          <dd>
            <time dateTime={parsedStartTime}>
              {parsedStartDate.getFormattedTime()}
            </time>{" "}
            -{" "}
            <time dateTime={parsedEndTime}>
              {parsedEndDate.getFormattedTime()}
            </time>
          </dd>
          <dd>{dress}</dd>
          {notes && <dd>{notes}</dd>}
        </dl>
      </div>
    </div>
  );
};

const DanceMonth = ({ startDate, children }) => {
  const parsedDate = parseDateString(startDate);
  const month = parsedDate.getMonthName();
  const year = parsedDate.dateTime.getFullYear();
  return (
    <div className="w-full sm:w-56 p-1 m-1 mb-4">
      <h3 className="bg-primary text-secondary rounded px-1">
        {month} {year}
      </h3>
      {children}
    </div>
  );
};

const Events = ({ data, location }) => {
  const futureEvents = events.filter(
    (e) => !parseDateString(e.endDate).isPastDate()
  );

  return (
    <>
      <SEO
        title="Dance Events"
        description="Social and Medal events for 2024 and 2025. Join us for a special events, social dances and dance medal qualifications!"
        keywords={[`social`, `dance`, `events`, `medals`]}
        location={location}
        image={data.og.sharp.fixed}
      />
      <ScrollUpButton />
      <Sidebar>
        <ul className="font-body text-sm">
          <li>
            <a href="#dance">Social Dances</a>
          </li>
          <li>
            <a href="#medals">Medals</a>
          </li>
        </ul>
      </Sidebar>
      <section>
        <Article id="dance" title="Upcoming Social Dances for 2025">
          {futureEvents.length > 0 ? (
            <div className="flex flex-row flex-wrap justify-between">
              {futureEvents.map((event, i) => {
                return (
                  <DanceMonth startDate={event.startDate} key={event.startDate}>
                    <DanceEvent
                      name={event.name}
                      place={event.venue}
                      startDate={event.startDate}
                      endDate={event.endDate}
                      dress={event.dress}
                      notes={event.notes}
                    />
                  </DanceMonth>
                );
              })}

              <div className="z-10 p-2 mt-4 mx-auto self-end border border-highlight text-center">
                <span className="text-highlight font-semibold select-text">
                  Paulton Rovers Club (BS39 7RF)
                </span>{" "}
                - Large car park and fully licensed Bar.
              </div>
            </div>
          ) : (
            <p className="mt-2">No events currently scheduled.</p>
          )}
        </Article>
      </section>
      <LineBreak />
      <section className="w-full mx-auto sm:w-4/5">
        <Article id="medals" title="Medals">
          <p>
            The Dancentre run regular medal tests for students in the school.
            Everyone from the children's classes through to the advanced classes
            can take medals. Please speak to your teacher if you are interested.
          </p>
          <h3 className="text-lg mt-2">Dates for medal tests:</h3>
          <ul className="list-disc list-inside pl-2">
            <li>13th July, 2025</li>
            <li>14th December, 2025</li>
          </ul>
          <p className="mt-2">
            To be held at{" "}
            <Link
              to="/hillside-hall"
              className="hover:underline text-highlight"
            >
              Hillside Hall
            </Link>
            .
          </p>
        </Article>
      </section>
    </>
  );
};

export const query = graphql`
  query {
    og: file(relativePath: { eq: "events.jpg" }) {
      sharp: childImageSharp {
        fixed(width: 1200) {
          width
          height
          src
        }
      }
    }
  }
`;

export default Events;
